import { createContext } from "react";
import swal from "sweetalert";
import { CHAIN_NAMESPACES, WEB3AUTH_NETWORK } from "@web3auth/base";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { Web3Auth } from "@web3auth/modal";
import { WalletServicesPlugin } from "@web3auth/wallet-services-plugin";
export const MyContext = createContext();


//Live
// export const apiUrl = "https://api.betfolio.co/api/v1"
// export const baseUrl = "https://api.betfolio.co/"
// export const chainId = 137;
// export const tokenAddress = "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359";//live mainnet
// export const consttransactionurl = "https://polygonscan.com/tx/";
// export const metamaskUrl = "https://metamask.app.link/dapp/betfolio.co/"
// export const divideBy = 1000000;
// export const decimalTo = 6;
// export const betCounterAddress = "0x88CD2402f3A486DCd2364c0b0BB9b2C81546b8A3"
// export const soloCounterAddress = "0x1fAA3c5d8692fb1571f8D433B39A1484765cbfD5"
// export const soloNft = "0x34107Deb9348Aa4daA8e8fAa5dAcaDe2FbbdAFF1"
// export const soloExtendedNft = "0xbeaE75BBd61AC96f1DA1ea07FA1eF8473cF0EAf7"
// export const groupNft = "0x2A7f6Cca4BbAa8a3cd12A1dE9c8e15fEF0F31D01"
// export const multiChoiceNft = "0xF3DcF23919136107535b2728E4627087C4d22b71"
// export const multiChoiceBetCounter = "0x639cc78918a57d43aaf948fde0d02f95055d4005"
// export const appUrl = "https://betfolio.co"
// export const folioContractAddress = '0x2877BC2369a42634becb2d9A4dCf77D97a7230C4';
// export const nftMinterAddress = "0x8C3fc7D87081eD1eD080EAe22f0E68Fd3F866006" // new minter address
// export const WEB3_PROVIDER = "https://polygon-mainnet.g.alchemy.com/v2/8gDakbIcC4LVMnBpF_KG94Z8dNp49pb3"
// export const timeExtenderAddress = "0x6598618a777fd5fD70c9e2020A00786F1bfeFc24"
// export const NftBuyContractAddress = '0xc6ba950917be27d13e31ada1a42c262ff73077ad';


// export const soloTimeExtensionNFT_01_BETS_ADDRESS = "0xDEf65AbC4a23a4c9baff68bFACc2606CF17A5d49";
// export const soloTimeExtensionNFT_03_BETS_ADDRESS = "0x0396520B7F7e60e568482d8E50F610569cfbc3cE";
// export const soloTimeExtensionNFT_10_BETS_ADDRESS = "0x7A450AC0410F161Cb1837127eAfF50820D8e31d9";
// export const multiTimeExtensionNFT_01_BETS_ADDRESS = "0xCCad03162E35EF455716A537EEF0F52899a7D3CB";
// export const multiTimeExtensionNFT_20_BETS_ADDRESS = "0x43131f9B25E636563Cf3851E603edE566901A105";
// export const clientId = "BEBmKk7gbN6wk-bM8f01LYUGUEKNjkU-quhFUHEE7MKJK01SlH6hNubSoa0zB8ZjvjWsZEwz2jNwrjRvgWooq08" //MAINNET live

// export const mintNftsList = [

//   {
//     mintId: 0,
//     mintName: "Group NFT",
//     tokenValue: 4,
//     contract: groupNft,
//     contractType: "GROUP",
//     bets: 0
//   },
//   {
//     mintId: 1,
//     mintName: "BetFolio Solo Prediction Pass",
//     tokenValue: 1.5,
//     contract: soloNft,
//     contractType: "BET_FOLIO_SOLO",
//     bets: 0
//   },
//   {
//     mintId: 2,
//     mintName: "BetFolio Extended Solo Prediction Pass",
//     tokenValue: 5,
//     contract: soloExtendedNft,
//     contractType: "EXTENDED_SOLO",
//     bets: 0

//   },
//   {
//     mintId: 3,
//     mintName: "BetFolio Multichoice Predictions Pass",
//     tokenValue: 1.5,
//     contract: multiChoiceNft,
//     contractType: "BET_FOLIO_MULTI",
//     bets: 0
//   },
//   {
//     mintId: 4,
//     mintName: "Solo Time Extension NFT - 1 bet",
//     tokenValue: 0.75,
//     contract: soloTimeExtensionNFT_01_BETS_ADDRESS,
//     contractType: "SOLO",
//     bets: 1
//   },
//   {
//     mintId: 5,
//     mintName: "Solo Time Extension NFT - 3 bets",
//     tokenValue: 1.5,
//     contract: soloTimeExtensionNFT_03_BETS_ADDRESS,
//     contractType: "SOLO",
//     bets: 3
//   },
//   {
//     mintId: 6,
//     mintName: "Solo Time Extension NFT - 10 bets",
//     tokenValue: 3.5,
//     contract: soloTimeExtensionNFT_10_BETS_ADDRESS,
//     contractType: "SOLO",
//     bets: 10
//   },
//   {
//     mintId: 7,
//     mintName: "Multi-choice Time Extension NFT - 1 bet",
//     tokenValue: 1,
//     contract: multiTimeExtensionNFT_01_BETS_ADDRESS,
//     contractType: "MULTI",
//     bets: 1
//   },
//   {
//     mintId: 8,
//     mintName: "Multi-choice Time Extension NFT - 20 bets",
//     tokenValue: 5,
//     contract: multiTimeExtensionNFT_20_BETS_ADDRESS,
//     contractType: "MULTI",
//     bets: 20
//   }
// ];

//**************************** Live Ends ************************************************************===>

// **************************** staging==Start ************************************************************===>

export const apiUrl = "https://betfolio-api.ecomempire.in/api/v1"
export const baseUrl = "https://betfolio-api.ecomempire.in/"
export const chainId = 137;
export const appUrl = "https://betfolio.ecomempire.in"
export const tokenAddress = "0x04BcEfE854Bcfee99d6921249db6F83090b5B2b8";
export const consttransactionurl = "https://polygonscan.com/tx/";
export const metamaskUrl = "https://metamask.app.link/dapp/betfolio.ecomempire.in/"
export const divideBy = 1000000;
export const decimalTo = 6;
export const betCounterAddress = "0x8627e69605adc9317A200A79536577B2c6F1DfDf"
export const soloCounterAddress = "0x6585bf144E4f1d1763eF63B17E836f54C2Eb3eBB"
export const soloNft = "0x177Cb9f5Fe566dA86443f54312c0e4F4F9285ce0"
export const soloExtendedNft = "0xBCbb8Ed8Ac13972cC46575D63A080537e233D9A4"
export const groupNft = "0x565b610BBEf2ECADCfdc175313190f0143F6E5CB"
export const multiChoiceNft = "0x85e8Ae1B4d145D7707EA926B21983df7E959F4cb"
export const multiChoiceBetCounter = "0x8F3EFFFD422CD5b1d9fe8497c6f63f05B88F2640"
export const folioContractAddress = '0x85114f24b621157FFd5b4ad9CEbB60333E7E1da4';
export const nftMinterAddress = "0xc179327de7d1039f3d2fd72e24e9d574f12b7fa5"
export const timeExtenderAddress = "0x236a25478efFd93B35A255483d2B0543ab41d797"
export const WEB3_PROVIDER = "https://polygon-mainnet.g.alchemy.com/v2/7yxaxXwiBlHGtpDrsUZzZJOJHN1dR2yf"
export const NftBuyContractAddress = '0x91a8989AA56667717828737595a0dDb0f6cDBDA0';

export const soloTimeExtensionNFT_01_BETS_ADDRESS = "0x4159a4bC57C0D8627117F24f1C5670eA4DF30e6C";
export const soloTimeExtensionNFT_03_BETS_ADDRESS = "0x998874c33358A38bcf40a8304C17B55560747e2f";
export const soloTimeExtensionNFT_10_BETS_ADDRESS = "0x511687B1c62F22C932C89DCE45D00bf832DB92D9";
export const multiTimeExtensionNFT_01_BETS_ADDRESS = "0x5A1419553D4F7FB656F72216BCA38F59bFd78BD4";
export const multiTimeExtensionNFT_20_BETS_ADDRESS = "0x5F508D3D5420432e0ac1089264b34BDB0B3B7957";
export const clientId = "BM3eS4IOgOsegcA96wwSu09whiVftpOaK3uTYr14sA7o2ehEeS96x1SSmSJBFJ34po3q8vmJGBgbL-Cy68ImX2w" //MAINNET staging
// export const clientId = "BKW6_egDTCqEHhLy2qBvPbnWF4qVLbdJk3gWjz6AtwncL9fNW7B1pkXtyWHVO-SMPl3Mu2zPCoumR_9u8xgPWmY" //test ajit

export const mintNftsList = [
  {
      mintId: 0,
      mintName: "Group NFT",
      tokenValue: 4,
      contract:"0xE99D8d709679D76B7465fC7c3ff57b862BaFD547",
      contractType:"GROUP",
      bets:0
  },
  {
      mintId: 1,
      mintName: "BetFolio Solo Prediction Pass",
      tokenValue: 3.8,
      contract:"0x7408BbB4C77Ad70EA827901C8FAC9a4f61F77371",
      contractType:"BET_FOLIO_SOLO",
      bets:0
  },
  {
      mintId: 2,
      mintName: "BetFolio Extended Solo Prediction Pass",
      tokenValue: 11.4,
      contract:"0xBd4fECE74B7855D894666163EE294Da04249b3a5",
      contractType:"EXTENDED_SOLO",
      bets:0

  },
  {
      mintId: 3,
      mintName: "BetFolio Multichoice Predictions Pass",
      tokenValue: 2.5,
      contract:"0xd3FEaF810fC9bE017B0DeFC8458d7D1b73e0A0a8",
      contractType:"BET_FOLIO_MULTI",
      bets:0
  },
  {
    mintId: 4,
    mintName: "Solo Time Extension NFT - 1 bet",
    tokenValue: 1.5,
    contract: soloTimeExtensionNFT_01_BETS_ADDRESS,
    contractType: "SOLO",
    bets: 1
  },
  {
    mintId: 5,
    mintName: "Solo Time Extension NFT - 3 bets",
    tokenValue: 3,
    contract: soloTimeExtensionNFT_03_BETS_ADDRESS,
    contractType: "SOLO",
    bets: 3
  },
  {
    mintId: 6,
    mintName: "Solo Time Extension NFT - 10 bets",
    tokenValue: 7,
    contract: soloTimeExtensionNFT_10_BETS_ADDRESS,
    contractType: "SOLO",
    bets: 10
  },
  {
    mintId: 7,
    mintName: "Multi-choice Time Extension NFT - 1 bet",
    tokenValue: 2,
    contract: multiTimeExtensionNFT_01_BETS_ADDRESS,
    contractType: "MULTI",
    bets: 1
  },
  {
    mintId: 8,
    mintName: "Multi-choice Time Extension NFT - 20 bets",
    tokenValue: 10,
    contract: multiTimeExtensionNFT_20_BETS_ADDRESS,
    contractType: "MULTI",
    bets: 20
  }
];
// **************************** staging Ends ************************************************************===>


// testing sepola start
// export const apiUrl = "https://test-api.betfolio.co/api/v1"
// export const baseUrl = "https://test-api.betfolio.co/"
// export const appUrl = "https://test.betfolio.co"
// export const chainId = 11155111;
// export const tokenAddress = "0x5E6168E0B90Ec559CF0376c4BFDCa4a5689Ae943";
// export const consttransactionurl =  "https://mumbai.polygonscan.com/tx/";
// export const divideBy = 1000000;
// export const decimalTo = 6;
// export const metamaskUrl = "https://metamask.app.link/dapp/betfolio.ecomempire.in/"
// export const betCounterAddress = "0x4F1CEAA9D4C341633df0dFd9CD29Ebbb4d8B92E0"
// export const soloCounterAddress = "0xD6fc74A611e1A73E57644B47791E674785F15365"
// export const soloNft = "0x4CE9307709D324508b60a627351E86302fe3b711"
// export const soloExtendedNft = "0xDADbAF873940B5C13e91A1e0dC8546199fD08d92"
// export const groupNft = "0xfC4C01F07c021e8466a7F08d9077d65C1EE4C3FA"
// export const multiChoiceNft = "0xe47a3b8EDa249eA364f1a0f5D2499112E8Dcb222"
// export const multiChoiceBetCounter = "0x61870B141748ED88b100AedA0E6429b1038C36Ab"
// export const folioContractAddress = '0x774946f2D38A5c1F98FD47ebf7cDb8b0dDd21FDb';
// export const nftMinterAddress = "0x2dcc86598552ea78ef27c9f3c3c11df50f346ce6"
// export const timeExtenderAddress = "0x4f6d3e239445ff6a0b87612f692cfc01e1baf7a1"
// export const WEB3_PROVIDER="https://eth-sepolia.g.alchemy.com/v2/iRbfEgrM-fGIxCHvIiKg2feE05kjAU41"

// export const clientId = "BDbKcWJM37MRySHMQO-qvrWIUSPOZ5Ew5yYNGZPUBhfRWUZNdREPnUTLpGXwPlJlQASgDTilUW569tLAqQNKjBI" // Web3Auth clientId    TESTNET
// testing sepolia End


export const gasFee = 21000
export const gasFeePercent = 1.9
export const folioDecimals = 18;
export const httpproviderPOLY = "https://polygon-mumbai.g.alchemy.com/v2/ZbBgRPLIBdkabBTDqNGXe03I5Lh_pYmO"


export const indexcubeMetamaskUrl = "https://metamask.app.link/dapp/betfolio.co//"
export const Unauthorized = () => {
  swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
    localStorage.clear()
    window.location.href = "/";
    console.log('OK button clicked after error alert');
  })
}


export function capitalizeFirstLetter(str) {
  console.log("str", str, str.toString());
  str = str.toString();
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const formatWalletAddress = (address) => {
  if (!address) return '';
  const firstPart = address.slice(0, 4);
  const lastPart = address.slice(-4);
  return `${firstPart}...${lastPart}`;
};




export const commissionArray = [{ amountmin: 0, amountmax: 1000, commission: 3 }, {
  amountmin: 1001, amountmax: 5000, commission: 2,
}, { amountmin: 5001, amountmax: 10000, commission: 1 }, { amountmin: 10001, amountmax: 1000000000000, commission: 0.5 }]


export const chainConfig = {
  chainNamespace: CHAIN_NAMESPACES.EIP155,
  chainId: "0x89",
  rpcTarget: "https://rpc.ankr.com/polygon",
  displayName: "Polygon Mainnet",
  blockExplorerUrl: "https://polygon.etherscan.io",
  ticker: "MATIC",
  tickerName: "Polygon",
};

// export const chainConfig = {
//   chainNamespace: CHAIN_NAMESPACES.EIP155,
//   chainId: "0xaa36a7",
//   rpcTarget: "https://rpc.ankr.com/eth_sepolia",
//   // Avoid using public rpcTarget in production.
//   // Use services like Infura, Quicknode etc
//   displayName: "Ethereum Sepolia Testnet",
//   blockExplorerUrl: "https://sepolia.etherscan.io",
//   ticker: "ETH",
//   tickerName: "Ethereum",
//   logo: "https://cryptologos.cc/logos/ethereum-eth-logo.png",
// };

export const privateKeyProvider = new EthereumPrivateKeyProvider({
  config: { chainConfig },
});


export const web3auth = new Web3Auth({
  clientId,
  web3AuthNetwork: WEB3AUTH_NETWORK.SAPPHIRE_MAINNET,
  privateKeyProvider,
  uiConfig: {
    appName: "BetFolio",
    appUrl: appUrl,
    logoDark: `${baseUrl}email-image/logo-betfolio.png`,
    logoLight: `${baseUrl}email-image/logo-betfolio.png`
  },
  sessionTime: 86400 * 7,
});

export const walletServicesPlugin = new WalletServicesPlugin();

export async function showCheckout() {
  await walletServicesPlugin.showCheckout({
    tokenList: ['POL', 'USDT'],
    // receiveWalletAddress: walletAddress,
    show: true
  });
}

export const soloTimeExtensionNFT_01_MAX_BETS = 1;
export const soloTimeExtensionNFT_03_MAX_BETS = 3;
export const soloTimeExtensionNFT_10_MAX_BETS = 10;
export const multiTimeExtensionNFT_01_MAX_BETS = 1;
export const multiTimeExtensionNFT_20_MAX_BETS = 20;