import { Button, Col, Container, Modal, Row, Table } from "react-bootstrap";
import { useState, useEffect } from "react";
import moment from "moment";
import { apiService } from "../service/api.service";
import { formatAddress, formatBalance, formatBalanceWithDecimals } from "../common/connectWallet";
import Pagination from "react-js-pagination";
// import swal from "sweetalert";
import { consttransactionurl, decimalTo } from "../config/config"
import { ThreeDotSpinner } from "./loader/index"
import { adClient } from '../common/adConfig'
import { useSelector } from "react-redux";
import ViewNFT from "./ViewNFT";
const TransactionManagement = () => {
    const [loader, setLoader] = useState(false)
    const [transactions, setTransactions] = useState();
    const walletAddress = useSelector((state) => state.reducer.walletAddress);
    const [totalCount, settotalCount] = useState()
    const [page, setpage] = useState(0)
    useEffect(() => {
        userTransaction(page)
    }, [])
    async function userTransaction(page) {

        setLoader(true)
        try {
            const response = await apiService.userTransaction(page);
            if (response.status == 200) {
                console.log(response.data.data)
                setTransactions(response.data.data.predictionDetail)
                settotalCount(response.data.data.totalRecords)
            }
            setLoader(false)
        } catch (error) {

            setLoader(false);
            if (error?.response?.status == 401) {
                // swal({ text: "Unauthorized", button: "OK" }).then(() => {
                localStorage.clear();
                window.location.href = "/";
                // });
            } else {
            }
        }
    }

    function handlePageChange(pageNumber) {
        console.log("??????page", pageNumber)
        if (page != pageNumber - 1) {
            setpage(pageNumber - 1);
            userTransaction(pageNumber - 1)

        }

    }

    useEffect(() => {
        adClient.showBannerAd({
            adUnitId: "271833e4-a718-4d71-b73e-955e35b1b33d",  // live
            // adUnitId: "23a5da96-5f02-4884-b4f9-8ca6a5bc95d7",  // test
            containerId: "ads-banner-top"
        }, (errorMessage) => {
            console.log("errorMessage", errorMessage);
            // You can handle error here.
        })
    }, []);


    useEffect(() => {
        adClient.showBannerAd({
            adUnitId: "7ef30ab6-59e1-45b9-9072-591153082a1e", //live
            // adUnitId: "591b109d-963c-4173-804b-817eeeaa696a", // test
            containerId: "ads-home-right"
        }, (errorMessage) => {
            console.log("errorMessage", errorMessage);
            // You can handle error here.
        })
    }, []);

    useEffect(() => {
        adClient.showBannerAd({
            adUnitId: "bcfbb4d5-0c6c-49e4-804f-2eb1a21e61f0", //live
            // adUnitId: "dede3c24-01ed-4c28-870f-cc90a888a9c9", // test
            containerId: "ads-home-left"
        }, (errorMessage) => {
            console.log("errorMessage", errorMessage);
            // You can handle error here.
        })
    }, []);


  const [showNFTView, setShowNFTView] = useState(false);
    return (
        <>
            <div className="ads-banner-top" id="ads-banner-top">

            </div>
            <div id="ads-home-right">

            </div>
            <div id="ads-home-left">

            </div>
            <section className="transaction-management-area">
                {loader && <> <ThreeDotSpinner /></>}
                <Container>
                    <div className="dashboard-area-heading management-heading">
                        <Row className="justify-content-center">
                            <Col md={12} lg={12}>
                                <div className="transaction-top-section">
                                    <div className="transaction-top-heading">
                                        <h2>Transactions</h2>
                                    </div>
                                    {!walletAddress?.accounts && walletAddress ? <Button type="button" variant="unset" onClick={()=> setShowNFTView(true)}>View My NFT</Button>:""}
                                </div>

                            </Col>
                        </Row>
                        <Row className=" justify-content-center">
                            <Col md={12} lg={12}>

                                <div className="audit-request-box">

                                    <Table responsive="sm" className="UserListTable">
                                        <thead>
                                            <tr>
                                                <th>Sr. No.</th>
                                                <th>Prediction Name</th >
                                                <th>Prediction Type</th>
                                                <th>Amount</th>
                                                <th>Reward Amount</th>
                                                <th>Hash</th>
                                                <th>Answer</th>
                                                <th>Result</th>
                                                <th>Date</th>
                                                <th>EarnM Codes</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {transactions && transactions.length > 0 ?
                                                <>
                                                    {transactions && transactions.map((data, index) => {
                                                        const {predictionDetails, latestEntry} = data;
                                                        return (<tr key={index} className={Number(latestEntry?.reward_amount) > 0 ? "highlight-prize" : ""}>
                                                            <td>{index + 1}</td>
                                                            <td><a href={"/" + predictionDetails?.data_provider + "/" + predictionDetails?.contract_address} target="_blank">{predictionDetails?.question}</a></td>
                                                            <td>{predictionDetails?.prediction_type ? predictionDetails?.prediction_type == "RISK-FREE" ? "Prize pool" : predictionDetails?.prediction_type : "N/A"}</td>

                                                            <td> {formatBalanceWithDecimals(latestEntry?.amount, predictionDetails?.decimal ? predictionDetails?.decimal : decimalTo)} <span className="token_symbol">{predictionDetails?.symbol} </span></td>
                                                            <td>{latestEntry?.reward_amount != 0 ? formatBalanceWithDecimals((latestEntry?.reward_amount - latestEntry?.amount), predictionDetails?.decimal ? predictionDetails?.decimal : decimalTo) : 0.00} <span className="token_symbol">{predictionDetails?.symbol} </span></td>

                                                            <td><a target="_blank" href={consttransactionurl + latestEntry?.transaction_hash}>{formatAddress(latestEntry?.transaction_hash)}</a></td>
                                                            <td>{latestEntry?.prediction.toString()}</td>
                                                            <td>{predictionDetails?.result == null ? "Not Updated" : predictionDetails?.result.toString()}</td>
                                                            <td>{moment(predictionDetails?.date).format("yyyy-MM-DD, hh:mm A")}</td>
                                                            <td>
                                                                <p>{latestEntry?.mystry_code ? latestEntry.mystry_code :"-"}</p>
                                                                {latestEntry?.mystry_code ? <a className="redeem-now-btn" href="https://app.earnm.com/en/reveal/" target="_blank">Redeem Now</a>:""}
                                                            </td>
                                                        </tr>)
                                                    })}
                                                </> :
                                                <tr>
                                                    <td colspan="8">
                                                        <div className="no-data-area main-no-data table-no-data">
                                                            <img src={require("../assets/images/no-data.gif")} alt="img" />
                                                            <p> No Data Found</p>
                                                        </div>
                                                    </td>
                                                </tr>


                                            }
                                        </tbody>
                                    </Table>
                                    {transactions && transactions.length > 0 ?

                                        <Pagination
                                            activePage={page + 1}
                                            itemsCountPerPage={10}
                                            totalItemsCount={totalCount}
                                            pageRangeDisplayed={5}
                                            onChange={(e) => handlePageChange(e)}
                                            prevPageText={"Prev"}
                                            nextPageText={"Next"}
                                        />
                                        : null
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>

            <ViewNFT showNFTView={showNFTView}  setShowNFTView={setShowNFTView} />

            {/* modal */}
            {/* <Modal show={show} onHide={handleClose} centered className="view-nft-modal folio-popu">
                <Modal.Header closeButton>
                    <Modal.Title>View My NFTs</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-0 mt-2">
                    <div className="view-nft-body">
                        <Row>
                            <Col md={4}>
                                <div className="under-view-nft">
                                    <p>NFT Name: <span>Lending Protocol</span> </p>
                                    <p>Count: <span>10/10</span> </p>
                                    <p>Status: <span className="">10/10<span className="Valid-text ms-1"><i className="fa fa-ban me-1" aria-hidden="true"></i>
                                        Valid</span></span> </p>
                                    <p>Expiry countdown: <span>00:00:00 Sec</span> </p>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="under-view-nft">
                                    <p>NFT Name: <span>Lending Protocol</span> </p>
                                    <p>Count: <span>10/10</span> </p>
                                    <p>Status: <span className="">10/10<span className="Valid-text ms-1"><i className="fa fa-ban me-1" aria-hidden="true"></i>
                                        Valid</span></span> </p>
                                    <p>Expiry countdown: <span>00:00:00 Sec</span> </p>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="under-view-nft">
                                    <p>NFT Name: <span>Lending Protocol</span> </p>
                                    <p>Count: <span>10/10</span> </p>
                                    <p>Status: <span className="">10/10<span className="Valid-text ms-1"><i className="fa fa-ban me-1" aria-hidden="true"></i>
                                        Valid</span></span> </p>
                                    <p>Expiry countdown: <span>00:00:00 Sec</span> </p>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="under-view-nft">
                                    <p>NFT Name: <span>Lending Protocol</span> </p>
                                    <p>Count: <span>10/10</span> </p>
                                    <p>Status: <span className="">10/10<span className="Valid-text ms-1"><i className="fa fa-ban me-1" aria-hidden="true"></i>
                                        Valid</span></span> </p>
                                    <p>Expiry countdown: <span>00:00:00 Sec</span> </p>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="under-view-nft">
                                    <p className="border-0">NFT Name: <span>Solo Time Extension NFT</span> </p>
                                    <div className="subnodes">
                                        <p>1.5 USDC <span className="highlisgtd-text">(10%)</span></p>
                                        <p>1.5 USDC <span className="highlisgtd-text">(10%)</span></p>
                                        <p>1.5 USDC <span className="highlisgtd-text">(10%)</span></p>
                                    </div>
                                    <p>Count: <span>123</span> </p>
                                    <p>Status: <span className=""><span className="Valid-text ms-1"><i className="fa fa-ban me-1" aria-hidden="true"></i>
                                        Valid</span></span> </p>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="under-view-nft">
                                    <p className="border-0">NFT Name: <span>Solo Time Extension NFT</span> </p>
                                    <div className="subnodes">
                                        <p>1.5 USDC <span className="highlisgtd-text">(10%)</span></p>
                                        <p>1.5 USDC <span className="highlisgtd-text">(10%)</span></p>
                                        <p>1.5 USDC <span className="highlisgtd-text">(10%)</span></p>
                                    </div>
                                    <p>Count: <span>123</span> </p>
                                    <p>Status: <span className=""><span className="Valid-text ms-1"><i className="fa fa-ban me-1" aria-hidden="true"></i>
                                        Valid</span></span> </p>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="under-view-nft">
                                    <p className="border-0">NFT Name: <span>Solo Time Extension NFT</span> </p>
                                    <div className="subnodes">
                                        <p>1.5 USDC <span className="highlisgtd-text">(10%)</span></p>
                                        <p>1.5 USDC <span className="highlisgtd-text">(10%)</span></p>
                                        <p>1.5 USDC <span className="highlisgtd-text">(10%)</span></p>
                                    </div>
                                    <p>Count: <span>123</span> </p>
                                    <p>Status: <span className=""><span className="Valid-text ms-1"><i className="fa fa-ban me-1" aria-hidden="true"></i>
                                        Valid</span></span> </p>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="under-view-nft">
                                    <p className="border-0">NFT Name: <span>Solo Time Extension NFT</span> </p>
                                    <div className="subnodes">
                                        <p>1.5 USDC <span className="highlisgtd-text">(10%)</span></p>
                                        <p>1.5 USDC <span className="highlisgtd-text">(10%)</span></p>
                                    </div>
                                    <p>Count: <span>123</span> </p>
                                    <p>Status: <span className=""><span className="Valid-text ms-1"><i className="fa fa-ban me-1" aria-hidden="true"></i>
                                        Valid</span></span> </p>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="under-view-nft">
                                    <p className="border-0">NFT Name: <span>Solo Time Extension NFT</span> </p>
                                    <div className="subnodes">
                                        <p>1.5 USDC <span className="highlisgtd-text">(10%)</span></p>
                                        <p>1.5 USDC <span className="highlisgtd-text">(10%)</span></p>
                                    </div>
                                    <p>Count: <span>123</span> </p>
                                    <p>Status: <span className=""><span className="Valid-text ms-1"><i className="fa fa-ban me-1" aria-hidden="true"></i>
                                        Valid</span></span> </p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal > */}
        </>
    );
};
export default TransactionManagement;