import { Button, Col, Container, Row } from "react-bootstrap";
import { adClient } from '../common/adConfig'
import { useEffect, useRef, useState } from "react";

const Footer = () => {

  useEffect(() => {
    adClient.showBannerAd({
      adUnitId: "bfdfb406-c3d4-4f6c-b628-7f3e0356d1a0", // live
      // adUnitId: "4076ff16-dc8d-4a6e-b317-b7df215c083c", // test
      containerId: "ads-footer"
    }, (errorMessage) => {
      console.log("errorMessage", errorMessage);
      // You can handle error here.
    })
  }, []);

  return <>
    <div className="footer-area">

      <div id="ads-footer">

      </div>


      <section className="footer">
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <div className="footer-left-area">
                <ul>
                  <li><a href="/terms-services"> Terms or Service</a></li>
                  <li><a href="/privacy-policy">Privacy Policy</a> </li>
                </ul>
              </div>
            </Col>
            <Col md={6}>
              <div className="footer-content">
                <div className="social-icons">
                  <h6>Social Media Links </h6>
                  <ul>
                    <li><a href="https://warpcast.com/betfolio" target="blank"><img src={require("../assets/images/warpcast.png")} alt="img" /></a></li>
                    <li><a href="https://t.me/betfoliox" target="blank"><i class="fa fa-telegram" aria-hidden="true"></i></a></li>
                    <li><a href="https://twitter.com/BetFolioX" target="blank"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                  </ul>
                </div>
              </div>

            </Col>
          </Row>
        </Container>

      </section>
      <section className="bottom-footer">
        <Container>
          <p>All rights Reserved© betfolio.co, 2024</p>
        </Container>
      </section>
      {/* <img src={require("../assets/images/bottom-vector.png")} alt="img" /> */}
    </div>

  </>;
};
export default Footer;
